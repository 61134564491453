<template>
    <div ref="prev" style="width: 100%; height: 100%">
        <b-row ref="bgprev" id="bg-prev" :style="myStyles">
            <div>
                <div v-show="editDbMode">
                    <p>{{opt.title}}</p>
                    <b-button variant="secondary" size="sm" @click="modalEdit = !modalEdit">Edit chart</b-button>
                </div>

                <highcharts v-show="!editDbMode" class="chart" :options="chartOptions" :updateArgs="updateArgs" :deepCopyOnUpdate="true"></highcharts>
            </div>
            <b-modal title="Edit component" v-model="modalEdit" @ok="handleOk" @cancel="handleCancel">
                <b-form-group>
                    <b-row>
                        <b-col cols="6">
                            <strong>Storages to show</strong>
                            <b-form-select class="mt-1" v-model="storages" :options="storageOptions" multiple :select-size="10"></b-form-select>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <strong>Chart options</strong>
                            <b-row class="mt-1">
                                <b-col class="d-flex flex-row" cols="10" sm="9">
                                    <label for="title">Title:</label>
                                    <b-form-input class="ml-1" size="sm" id="title" type="text" v-model="opt.title"></b-form-input>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <b-form-input size="sm" id="titleColor" type="color" v-model="opt.titleColor"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="10" sm="9">
                                    <label for="axisColors">Axis color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <b-form-input size="sm" id="axisColors" type="color" v-model="opt.axisColors"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="10" sm="9">
                                    <label for="gridColor">Grid color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <b-form-input size="sm" id="gridColor" type="color" v-model="opt.gridColor"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="10" sm="9">
                                    <label for="legendColor">Legend color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <b-form-input size="sm" id="legendColor" type="color" v-model="opt.legendColor"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="10" sm="9">
                                    <label for="xLabelColor">x-axis label color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <b-form-input size="sm" id="xLabelColor" type="color" v-model="opt.xLabelColor"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="10" sm="9">
                                    <label for="y1LabelColor">y1-axis label color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <b-form-input size="sm" id="y1LabelColor" type="color" v-model="opt.y1LabelColor"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="10" sm="9">
                                    <label for="y2LabelColor">y2-axis label color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <b-form-input size="sm" id="y2LabelColor" type="color" v-model="opt.y2LabelColor"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <strong>Configuration templates</strong>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label for="newTemplateName">New template:</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-input size="sm" id="newTemplateName" type="text" v-model="newTemplateName"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="secondary" size="sm" @click="templateSave">Add</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label for="selectTemplate">Select template:</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-select size="sm" id="selectTemplate" type="text" :options="confTemplates" @change="templateChange" v-model="selectedTemplate" :select-size="1"></b-form-select>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="secondary" size="sm" @click="updateTemplate">Update</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <strong>Storages options:</strong>
                    <b-row v-for="(storage, index) in seriesConf" :key="storage.storageId">
                        <b-col cols="7">
                            <div class="mt-1">{{ storageOptions.find(x => x.value === storage.storageId).text }}</div>
                        </b-col>
                        <b-col cols="2">
                            <b-form-input size="sm" id="axisColors" type="color" v-model="seriesConf[index].color"></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <b-form-select size="sm" v-model="seriesConf[index].axis" :options="yaxisOptions"></b-form-select>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="remove"
                                     name="checkbox-remove"
                                     value="yes"
                                     unchecked-value="no">
                        Remove this component
                    </b-form-checkbox>
                </b-form-group>
            </b-modal>
        </b-row>
    </div>
</template>

<script>
    import { Chart } from 'highcharts-vue'
    import Highcharts from 'highcharts';
    import more from 'highcharts/highcharts-more';
    import boost from "highcharts/modules/boost";
    more(Highcharts);
    boost(Highcharts);
    import { bus } from '@/main.js';
    import { hcChartTmpl } from '@/assets/l4a_jsAssets.js'

    export default {
        name: 'largeChart',
        props: ['dbid', 'itemid', 'comp'],
        data() {
            return {
                x: 0,
                y: 0,
                modalEdit: false,
                selected: '',
                remove: 'no',
                opt: {},
                selectedStorages: [],
                seriesConf: [],
                yaxisOptions: ['y1', 'y2'],
                y1Unit: '',
                y2Unit: '',
                series: [],
                updateArgs: [true, true],
                newTemplateName: '',
                selectedTemplate: '-'
            }
        },
        computed: {
            myStyles() {
                return {
                    'width': '100%',
                    'height': '100%',
                    'background-color': (this.editDbMode) ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0)',
                    'border-style': (this.editDbMode) ? 'solid' : 'none',
                    'border-width': '1px',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'center',
                    'margin-left': '0px'
                }
            },
            componentOptions() {
                let c = [];
                let availableComponents = this.$store.getters.dbComponents
                for (let component of availableComponents) {
                    c.push({ text: component.name, value: component.name })
                }
                return c
            },
            storageOptions() {
                let options = []
                for (let storage of this.$store.getters.storages) {
                    options.push({ value: storage.placeId, text: storage.placeName })
                }
                return options
            },
            confTemplates() {
                let options = []
                for (let template of this.$store.getters.confTemplates) {
                    if (template.type == this.comp.name) options.push({ value: template.name, text: template.name });
                }
                return options
            },
            storages: {
                get: function () {
                    if (this.selectedStorages.length == 0 && typeof this.comp.config.storages !== 'undefined') {
                        return this.comp.config.storages
                    } else {
                        return this.selectedStorages
                    }
                },
                set: function (newStorages) {
                    // Update seriesConf
                    let tempSeries = JSON.parse(JSON.stringify(this.seriesConf))
                    this.seriesConf = []
                    for (let storage of newStorages) {
                        let index = tempSeries.findIndex(x => x.storageId === storage)
                        if (typeof index === 'undefined' || index == -1) {
                            this.seriesConf.push({ storageId: storage, axis: 'y1', color: '#000000' })
                        } else {
                            this.seriesConf.push(tempSeries[index])
                        }
                    }
                    this.selectedStorages = newStorages

                }
            },
            editDbMode() {
                return this.$store.getters.editDbMode;
            },
            chartOptions() {
                let options = JSON.parse(JSON.stringify(hcChartTmpl))
                let y1Unit = this.y1Unit
                let y2Unit = this.y2Unit
                options.chart.width = this.x
                options.chart.height = this.y
                options.xAxis.lineColor = this.opt.axisColors
                options.xAxis.tickColor = this.opt.axisColors
                options.xAxis.gridLineColor = this.opt.gridColor
                options.yAxis[0].gridLineColor = this.opt.gridColor
                options.yAxis[1].gridLineColor = this.opt.gridColor
                options.legend.itemStyle.color = this.opt.legendColor
                options.title.style.color = this.opt.titleColor
                options.title.text = this.opt.title
                options.xAxis.labels.style.color = this.opt.xLabelColor
                options.yAxis[0].labels = {
                    style: {
                        color: this.opt.y1LabelColor
                    },
                    formatter: function () {
                        return this.value + ' ' + y1Unit;
                    }
                }
                options.yAxis[1].labels = {
                    style: {
                        color: this.opt.y2LabelColor
                    },
                    formatter: function () {
                        return this.value + ' ' + y2Unit;
                    }
                }
                options.series.splice(0, options.series.length, ...this.series)
                return options
            }

        },
        created() {
            if (typeof this.comp.config.series !== 'undefined') {
                this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
            }
            if (typeof this.comp.config.storages !== 'undefined') {
                this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
            }
            if (typeof this.comp.config.opt !== 'undefined') {
                this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
            } else {
                this.opt.axisColors = hcChartTmpl.xAxis.lineColor
                this.opt.gridColor = hcChartTmpl.xAxis.gridLineColor
                this.opt.legendColor = hcChartTmpl.legend.itemStyle.color
                this.opt.titleColor = hcChartTmpl.title.style.color
                this.opt.title = hcChartTmpl.title.text
                this.opt.xLabelColor = hcChartTmpl.xAxis.labels.style.color
                this.opt.y1LabelColor = hcChartTmpl.yAxis[0].labels.style.color
                this.opt.y2LabelColor = hcChartTmpl.yAxis[1].labels.style.color
            }
            bus.$on('newData', () => {
                if (this.$store.getters.data.length > 0 && typeof this.comp.config.storages !== 'undefined' && this.comp.config.storages.length > 0) {
                    let series = []
                    let yAxis = null
                    let y1Unit = ''
                    let y2Unit = ''
                    for (let storage of this.comp.config.storages) {
                        if (this.$store.getters.data.findIndex(x => x.placeId === storage) !== -1) {
                            let serieConfig = this.seriesConf.find(x => x.storageId === storage)

                            if (serieConfig.axis == 'y1') {
                                yAxis = 0
                                if (y1Unit == '') {
                                    let metricId = this.$store.getters.storages.find(x => x.placeId === storage).metricId
                                    if (metricId !== 'tbd') {
                                        y1Unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                                    }
                                }
                            } else if (serieConfig.axis == 'y2') {
                                yAxis = 1
                                if (y2Unit == '') {
                                    let metricId = this.$store.getters.storages.find(x => x.placeId === storage).metricId
                                    y2Unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                                }
                            }
                            series.push({
                                name: this.$store.getters.storages.find(x => x.placeId === storage).placeName,
                                id: this.$store.getters.storages.find(x => x.placeId === storage).placeName,
                                data: this.$store.getters.data.find(x => x.placeId === storage).hcData,
                                yAxis: yAxis,
                                type: (this.$store.getters.data.find(x => x.placeId === storage).hcData < 400) ? 'spline' : 'line',
                                boostThreshold: 400,
                                animation: false,
                                color: serieConfig.color,
                                lineWidth: 1,
                                zIndex: 1
                            })
                            if (this.$store.getters.timePeriod.period > 24 * 7) {
                                series.push({
                                    name: this.$store.getters.storages.find(x => x.placeId === storage).placeName + "_range",
                                    linkedTo: this.$store.getters.storages.find(x => x.placeId === storage).placeName,
                                    data: this.$store.getters.data.find(x => x.placeId === storage).hcDataRange,
                                    yAxis: yAxis,
                                    type: 'arearange',
                                    animation: false,
                                    lineWidth: 0,
                                    fillOpacity: 0.3,
                                    zIndex: 0,
                                    marker: {
                                        enabled: false
                                    },
                                    color: serieConfig.color
                                })
                            }
                        }
                    }
                    this.y1Unit = y1Unit
                    this.y2Unit = y2Unit
                    this.series = series
                }
            });
            bus.$on('itemResized', (newSize) => {
                if (newSize.i == this.itemid) {
                    this.x = newSize.w
                    this.y = newSize.h
                }
            })
        },
        mounted() {
            this.x = this.$refs.prev.clientWidth;
            this.y = this.$refs.prev.clientHeight;
            //this.getWindowSize();
            this.$nextTick(() => {
                window.addEventListener('resize', () => {
                    this.$nextTick(() => {
                        if (typeof this.$refs.prev !== 'undefined') {
                            this.x = this.$refs.prev.clientWidth;
                            this.y = this.$refs.prev.clientHeight;
                        }
                    });
                });
            })
        },
        methods: {
            handleOk() {
                let payload = {
                    dbid: this.dbid,
                    itemid: this.itemid,
                    newConfig: {
                        storages: this.selectedStorages,
                        /*chartOptions: this.chartOptions,*/
                        opt: this.opt,
                        series: this.seriesConf
                    }
                }
                this.$store.commit('updateCompConfig', payload);
                // add additional component
                if (this.selected != '') {
                    let compIndex = this.$store.getters.dbComponents.findIndex(x => x.name === this.selected);
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid,
                        newComp: this.$store.getters.dbComponents[compIndex]
                    }
                    this.selected = '';
                    this.$store.commit('addComp', payload);
                } else if (this.remove == 'yes') {
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid
                    }
                    this.remove = 'no'
                    this.$emit('removed', payload)
                }
                this.$store.commit('updateStorageIds', this.dbid)
            },
            handleCancel() {
                if (typeof this.comp.config.series !== 'undefined') {
                    this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
                }
                if (typeof this.comp.config.storages !== 'undefined') {
                    this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
                }
                if (typeof this.comp.config.opt !== 'undefined') {
                    this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                }
            },
            templateChange() {
                let tmpl = JSON.parse(JSON.stringify(this.$store.getters.confTemplates.find(x => x.name === this.selectedTemplate).tmpl))
                tmpl.opt.title = this.opt.title
                this.opt = tmpl.opt
            },
            templateSave() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.newTemplateName,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                this.$store.commit('addConfTemplate', payload)
                this.selectedTemplate = this.newTemplateName
                this.newTemplateName = ''
            },
            updateTemplate() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.selectedTemplate,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                console.log(payload)
                this.$store.commit('updateConfTemplate', payload)
            }
        },
        components: {
            highcharts: Chart
        }
    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>